import "./App.css";
import React, { useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import ReCAPTCHA from "react-google-recaptcha";

function App() {
  const [isSuccess, setIsSuccess] = useState(null);
  const [responseMsg, setResponseMsg] = useState(null);
  const [formData, setFormData] = useState({
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(true);
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    if (!captchaValue) {
      alert("Please complete the reCAPTCHA");
      return;
    }

    try {
      const response = await axios.post(
        "https://r59bqlvock.execute-api.us-west-1.amazonaws.com/prod/send-feedback",
        { ...formData, "g-recaptcha-response": captchaValue }
      );

      console.log(response);

      const data = response.data;

      if (response.status >= 200 && response.status < 300) {
        setIsSuccess(true);
        setFormData({ message: "" });
        setVisible(false);
      } else {
        setIsSuccess(false);
      }

      setResponseMsg(data.message);
    } catch (error) {
      console.error("Error:", error);
      setIsSuccess(false);
      setResponseMsg(
        "Sorry, We could not send your email. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <div className="App">
      <div>
        <img
          src="https://darkstarcdn.s3.us-east-2.amazonaws.com/smh-logo.png"
          alt="Logo"
          className="logo"
        />
        <form
          className={`form-container ${!visible && "form-container-fade-out"}`}
          onSubmit={handleSubmit}
        >
          <div className={`fade ${!visible && "fade-out"}`}>
            <TextField
              label="Share your feedback anonymously"
              name="message"
              value={formData.message}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              fullWidth
              multiline
              rows={4}
            />
          </div>
          <div
            className={`fade ${!visible && "fade-out"}`}
            style={{ marginTop: "20px" }}
          >
            <ReCAPTCHA
              sitekey="6Lfoh_cpAAAAAEkovkhHpGLm5NuYi_NpoNUVNws2"
              onChange={handleCaptchaChange}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={loading || !formData.message}
              sx={{
                backgroundColor: "#cda462",
                color: "#fff",
                "&:hover": { backgroundColor: "#b08d54" },
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Share feedback"
              )}
            </Button>
          </div>
          {responseMsg && <p className="response-message">{responseMsg}</p>}
          {isSuccess === true ? (
            <p className="response-message">
              <CheckCircleIcon color="success" fontSize="large" />
            </p>
          ) : isSuccess === false ? (
            <p className="response-message">
              <ErrorIcon color="error" fontSize="large" />
            </p>
          ) : null}
        </form>
      </div>
    </div>
  );
}

export default App;
